import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AplicativoDto } from '../../interfaces/aplicativos/aplicativos.dto';

@Injectable({
  providedIn: 'root',
})
export class MockJsonLoaderService {
  constructor(private http: HttpClient) { }

  getDiagramas(): Observable<any> {
    return this.http.get('assets/mocks/diagramas.json');
  }

  getOrganograma(): Observable<any> {
    return this.http.get('assets/mocks/organograma.json');
  }

  getRoadmapPrev(): Observable<any> {
    return this.http.get('assets/mocks/roadmap-prev.json');
  }

  getRoadmapNext(): Observable<any> {
    return this.http.get('assets/mocks/roadmap-next.json');
  }

  listAplicativos(): Observable<AplicativoDto[]> {
    return this.http.get<AplicativoDto[]>('assets/mocks/apps.json');
  }
}
